setTimeout(function(){
    // init de algolia
    thuriaSearch.init(
        algolia.application_id,
        algolia.search_api_key,
        ['apidae'], // index(s) pour container apidae
        ['page','magazine'], // index(s) pour container un peu de lecture
        ['post'], // index(s) pour container pour les pros
        $('#inputfastsearch').get(0), // input de saisie
        $('#fast-search-container .wrapper').get(0), // résultats apidae
        $('.wrapper-search-algolia').get(0), // scroller pour chargement page suivante
        $('#fast-search-container-cms .owl-carousel').get(0), // résultats page + magazine
        $('#fast-search-container-pages-pro .owl-carousel').get(0) // résultats post
    );
},1000);